/*
 * @Author: your name
 * @Date: 2022-04-11 14:00:24
 * @LastEditTime: 2022-04-26 11:13:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/view/partner/apply/applyList/index.js
 */
import { delMenuActions, getMenuAction } from "@lib/apply-use";
import { getAppMenus } from "@lib/apply-menu";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      data: [],
      menuId: "0",
      codeId: "",
      fullName: "",
      defaultProps: {},
    };
  },
  methods: {
    handleNodeClick({ id, name }) {
      if (id === this.menuId) return;
      this.menuId = id;
      this.menuName = name;
      this.getListFuction();
    },
    // 新增菜单 | 查看
    addAccount(info, editOrSee) {
      sessionStorage.setItem("menuId", this.menuId);
      if (info == undefined) {
        this.$router.push({
          name: "applyUseAdd",
          query: { menuName: this.menuName, menuId: this.menuId },
        });
      } else {
        /* this.$confirm("修改应用后需重新审核，确定要修改？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => { */
        this.$router.push({
          name: "applyUseAdd",
          params: { info: JSON.stringify(info), editOrSee },
          query: { menuName: this.menuName, menuId: this.menuId },
        });
        /*  })
          .catch(() => {}); */
      }
    },
    //获取树
    async getTree() {
      const { result } = await getAppMenus({ appId: this.codeId });
      const table = result
        .filter((item) => !item.pid || item.pid === "ROOT")
        .map((item) => {
          item.children = this.initCascader(
            result.filter((item) => item.pid),
            "pid",
            item.id
          );
          item.label = item.name;
          return item;
        });
      this.data = table;

      this.$nextTick(() => {
        this.menuId =
          this.sessGet("menuId") || (table.length ? table[0].id : "0");
        this.menuName = table.length ? table[0].name : "";
        this.$refs.tree.setCurrentKey(this.menuId);
        this.getListFuction();
      });
    },
    //获取table
    async getListFuction() {
      let { result } = await getMenuAction({ menuId: this.menuId });
      this.tableData = result;
    },
    async addAccountDel(val) {
      this.$confirm("确定删除该功能？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delMenuActions({ ids: val.id }).then((res) => {
            if (res.status === "success") {
              this.getListFuction();
              this.$message.success("删除成功");
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.getTree();
  },
  created() {
    this.initDictData("SHOW_TYPE_DICT");
    let applyPage = sessionStorage.getItem("applyPage");
    if (applyPage) {
      this.page = Number(applyPage);
    }
    const info = sessionStorage.getItem("applyUse");
    const { code, fullName } = JSON.parse(info);
    this.codeId = code;
    this.fullName = fullName;
  },
};
